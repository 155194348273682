<script>
	export let nextText;
	export let disabled = false;
</script>

<button class="button" type="submit" {disabled}>
	{nextText}
	<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M10.5858 6.34317L12 4.92896L19.0711 12L12 19.0711L10.5858 17.6569L16.2427 12L10.5858 6.34317Z"
			fill="currentColor"
		/>
	</svg>
</button>

<style>
	button {
		display: flex;
		align-items: center;
		justify-content: flex-end;
		padding-right: 24px;
		margin-top: 48px;
	}
</style>
